/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
    --theme-font: 'Inter', sans-serif;
    --theme-fw: 400;
    --theme-fwsb: 600;
    --theme-green: #6CA942;
    --theme-font-size: 15px;
    --theme-font-big: 19px;
    --theme-font-color: rgba(0, 0, 0, 0.6);
    --theme-font-color1: rgba(0, 0, 0, 0.7);
    --theme-font-color2: rgba(0, 0, 0, 0.8);
    --theme-black: #000;
    --theme-blue: #2B4A9E;
    --theme-error-color: red;
}

h1,
.h1 {
    font-size: 30px;
}

.right-wrap {
    form {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 99;
    }
}

.container-login {
    .right-wrap {
        padding: 0 45px;
    }

    .form-group {
        position: relative;

        mk-error {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

body {
    font-family: var(--theme-font);
    font-weight: var(--theme-fw);
    font-size: var(--theme-font-size);
    color: var(--theme-black);
}

p {
    font-family: var(--theme-font);
    font-weight: var(--theme-fw);
    font-size: var(--theme-font-size);
    color: var(--theme-font-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: var(--theme-fwsb);
}

a.forget-password.hightlight {
    font-family: var(--theme-font);
    font-weight: var(--theme-fw);
    font-size: var(--theme-font-size);
    color: var(--theme-font-color);
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
}

mk-input,
mk-password {
    margin-bottom: 20px;

    label {
        font-weight: 600;
        color: var(--theme-font-color);
        margin-bottom: 5px;
    }

    input {
        &.form-control {
            height: 50px;
        }

        &:focus {
            box-shadow: none !important;
        }
    }
}

.modal-header {
    padding: 1rem 25px !important;
    background: rgba(108, 169, 66, 0.1) !important;
    border-bottom: none;

    button.close-icon {
        background: rgba(255, 255, 255, 0.5);
        transition: 0.5s all ease;

        &:hover {
            background: rgba(108, 169, 66, 0.5);
            transition: 0.5s all ease;
        }
    }
}

.modal-body {
    padding: 25px !important;
    max-height: calc(100vh - 150px);
    overflow: auto;
}

button {
    &.btn.btn-primary {
        background: var(--theme-blue);
        border: 2px solid var(--theme-blue);
        padding: 10px 30px;
        font-weight: 600;
        min-width: 120px;

        &:hover {
            background: var(--theme-blue);
            // color: var(--theme-blue);
        }

        // &.active {
        //     background: var(--theme-blue);
        // }
    }

    &.btn-light {
        background: transparent;
        border: 2px solid var(--theme-font-color);
        padding: 10px 30px;
        font-weight: 600;
        color: var(--theme-font-color);
        min-width: 120px;

        &:hover {
            background: transparent !important;
            // color: #fff;
        }
    }
}


.green-btn {
    button {
        &.btn.btn-primary {
            background: var(--theme-green);
            border: 2px solid var(--theme-green);
            padding: 10px 30px;
            font-weight: 600;

            &:hover {
                background: transparent;
                color: var(--theme-green);
            }
        }
    }
}

span.highligh-check {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 2px;
    cursor: pointer;
}

.container-login {

    .left-wrap {
        &:after {
            position: absolute;
            content: "";
            background: url(/assets/ipos-logo.png);
            width: 100%;
            height: 220px;
            background-size: 30vh;
            // background-size: 220px;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-repeat: no-repeat;
            top: 70px;
            text-align: center;
            background-position: top center;
        }
    }

    .right-wrap {

        h1,
        p {
            z-index: 99;
            position: relative;
            // margin-bottom: 25px;
        }

        &:before {
            position: absolute;
            content: '';
            background: url(/assets/login-arrow.png);
            width: 100%;
            height: 100%;
            background-size: 380px;
            background-position: center center;
            // background-size: 560px;
            // background-position: right 40px center;
            background-repeat: no-repeat;
            right: 0;
            top: 0;
            z-index: 1;
        }

        &:after {
            position: absolute;
            content: '';
            background: url(/assets/theme-bg.png);
            width: 100%;
            height: 100%;
            left: 0;
            z-index: 1;
            opacity: 1;
            background-size: cover;
            background-position: center;
        }
    }
}

.modal-fullwidth {
    position: fixed;
    overflow: hidden;

    .modal-content {
        height: calc(100vh - 20px);
    }

    .modal-header {
        background: #fff;
        border-bottom: 1px solid rgba(43, 74, 158, 0.15);

        button.close-icon {
            background: rgba(121, 183, 78, 0.2);
            line-height: initial;
            display: flex;
            align-items: center;
            height: 35px;
            transition: 0.5s all ease;

            &:hover {
                background: rgba(108, 169, 66, 0.5);
                transition: 0.5s all ease;
            }
        }
    }

    .modal-lg {
        --bs-modal-width: 100%;
        padding: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        height: 100%;

        .modal-body {
            padding: 35px !important;
            max-height: calc(100vh - 150px);
            overflow: auto;
        }
    }
}

.password-container {
    span {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 7px;
        right: 7px;
        background: rgba(121, 183, 78, 0.2);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            position: relative !important;
            top: 0 !important;
            right: 0 !important;
            color: var(--theme-green);
        }

    }
}

.com-space {
    padding-left: 70px;
    padding-right: 70px;
}

.grid-wrap {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    grid-auto-rows: minmax(100px, auto);
    margin-top: 10px;

    .grid-inner {
        background: #fff;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.05), 0 15px 12px rgba(0, 0, 0, 0.05);
        cursor: pointer;

        &:hover {
            h4 {
                color: var(--theme-green);
            }
        }

        img {
            width: 100%;
            border-radius: 12px;
            height: 215px;
            object-fit: cover;
        }

        h4 {
            // font-size: 26px !important;
            font-size: 21px !important;
            line-height: initial;
            padding: 20px 10px;
            color: var(--theme-font-color1);
            width: 95%;
            margin: 0;
        }
    }
}

.mk-middle {
    flex: 1;
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    overflow: auto;
    // padding-top: 3.5em;
    padding-top: 1.5em;
    // max-height: calc(100vh - 185px);

    &.mk-0 {
        padding-top: 0;
    }

    .sub-header {
        font-size: var(--theme-font-big);
    }
}


ul {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
    }
}

.error-container {
    span {
        font-size: 14px !important;
        font-weight: normal;
    }
}

mk-checkbox.ng-touched.ng-invalid {
    input {
        border: 1px solid var(--theme-error-color) !important;
        outline: max(1px, 1px) dashed var(--theme-error-color);
        outline-offset: max(1px, 0.15em);
    }
}

.ngb-toasts * {
    font-size: 12px !important;
}



.searchselect ul.mk2select-options.show {
    border-radius: 10px !important;
    border: none !important;
    position: relative !important;
    max-height: inherit !important;
    overflow-y: inherit !important;


    li.mk2select-option.search-box {
        top: 0;
        position: sticky;
        padding: 0;
        margin-bottom: 0 !important;
        display: inline-block;
        width: 100%;
        z-index: 99;
        cursor: pointer;

        &:after {
            position: absolute !important;
            content: "\F52A";
            display: inline-block;
            font-family: bootstrap-icons !important;
            font-style: normal;
            font-weight: normal !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            vertical-align: middle;
            -webkit-font-smoothing: antialiased;
            font-size: var(--theme-font-big);
            /* width: 22px; */
            /* height: 22px; */
            top: 15px;
            left: 15px;
            z-index: 999;
            visibility: visible !important;
            color: var(--theme-green)
        }

        .form-control {
            position: relative !important;
            height: 50px;
            border-radius: 10px !important;
            border: none !important;
            cursor: pointer;
            padding-left: 45px;
        }
    }
}

.mk2select-container {
    .mk2select-options {
        border: none !important;

        .mk2select-option.selected {
            background-color: rgb(108 169 66 / 10%) !important;
        }

        .mk2select-option.search-box {
            .form-control {
                position: relative !important;
                height: 45px;
            }
        }
    }

    .mk2selected-value {
        height: 50px;
        background: #fff;
        border: none !important;
        border-radius: 6px !important;
        padding-left: 45px !important;
        padding-right: 15px !important;

        i {
            width: 30px;
            height: 30px;
            background: rgba(108, 169, 66, 0.15);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            color: var(--theme-green);
        }

        &:after {
            display: none !important;
        }
    }

    .mk2select-options {
        .mk2select-option.search-box {
            margin-bottom: 0px !important;
        }
    }
}

ul.grid-list-3 {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    align-items: center;

    li.fd-icon {
        padding: 15px;
        background: #fff;
        text-align: center;
        height: 100%;
        border-radius: 20px;
        opacity: 0.5;
        cursor: pointer;
        transition: 0.5s all ease;

        &:hover,
        &.active {
            opacity: 1;
            transition: 0.5s all ease;

            img {
                mix-blend-mode: normal;
                transition: 0.5s all ease;
            }
        }

        img {
            // height: 42px;
            height: 36px;
            mix-blend-mode: luminosity;
            transition: 0.5s all ease;
        }

        p {
            margin-bottom: 0;
            margin-top: 6px;
            font-weight: 600;
        }
    }
}

.searchselect .mk-select2-list-items {
    position: absolute;
    z-index: 99;
    width: 100%;
    background: #fff;
    max-height: 215px;
    overflow-x: auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .mk2select-option {
        padding: 0.5rem 1rem 0.5rem 45px !important;
        position: relative;

        &:after {
            display: none !important;
            position: absolute !important;
            content: "\F52A";
            display: inline-block;
            font-family: bootstrap-icons !important;
            font-style: normal;
            font-weight: normal !important;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            vertical-align: -0.125em;
            -webkit-font-smoothing: antialiased;
            font-size: 15px;
            top: 13px;
            left: 15px;
            z-index: 999;
            visibility: visible !important;
            color: #A3A3A3;
        }

        label {
            margin-bottom: 0;
        }
    }
}

.mk-select2-list-items {
    .mk2select-option {
        padding: 0.5rem 1rem !important;

        label {
            margin-bottom: 0;
        }
    }
}

.box-align {
    background: linear-gradient(135deg, rgb(140 159 208 / 21%) 0%, rgb(144 179 136 / 21%) 100%);
    // padding: 45px;
    padding: 32px;
    border-radius: 20px;
}

.last-report {
    margin-top: -15px;

    p {
        margin-bottom: 5px;
    }
}

.mk2select {
    label {
        margin-bottom: 10px;
        color: var(--theme-font-color);
    }
}

.form-control:focus {
    box-shadow: none !important;
}

.mix-blend {
    .row * {
        mix-blend-mode: luminosity;
        opacity: 0.8;
        cursor: not-allowed;
    }

    ul.grid-list-3 li.fd-icon {
        pointer-events: none !important;

        &.active {
            opacity: 0.5;

            img {
                mix-blend-mode: luminosity;
            }
        }
    }

    .mk2select-container.disabled {
        cursor: not-allowed;
        pointer-events: none;

        .mk2selected-value.disabled {
            background-color: #fff !important;
            cursor: not-allowed !important;
            pointer-events: none;
        }
    }
}


.grid-inner.disable-elem {
    cursor: not-allowed;
    opacity: 0.4;

    img {
        mix-blend-mode: luminosity;
    }
}

*::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    // box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.5);
}

*::-webkit-scrollbar {
    width: 4px;
    // background-color: rgba(255, 255, 255, 0.2);
}

*::-webkit-scrollbar-thumb {
    // background-color: rgba(255, 255, 255, 0.3);
    background-color: rgb(108 169 66 / 59%);
}



.mklocations .mk2select-container .mk2select-options {
    max-height: auto !important;

    .mk-select2-list-items {
        max-height: 100px;
        overflow: auto;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}

.mklocations span.mk2selected-value:before {
    position: absolute !important;
    content: "\F3E7";
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    font-size: 20px;
    top: 15px;
    left: 15px;
    z-index: 999;
    visibility: visible !important;
    color: var(--theme-green);
}

.mklocations .mk-select2-list-items {
    .mk2select-option {
        padding: 0.5rem 1rem !important;
    }
}

@media screen and (min-width: 1300px) {
    .app-main_outer.auth-layout {
        width: 100%;
        margin: 0 auto;
    }
}


a.ngb-nav-link {
    color: black !important;
    background-color: white !important;
    border-radius: 40%;
    padding: 6px 20px 6px 24px;
}

a.ngb-nav-link:active {
    color: black !important;
    background-color: white !important;
    border-radius: 40%;
    padding: 6px 20px 6px 24px;
}

.delete-icon {
    color: #6aa94a;
    cursor: pointer;
    background-color: #CBE386;
    padding: 8px 8px;
}

.pitch-wrap {
    .nav-pills {
        .nav-item {
            .nav-link {
                padding-left: 0;
                display: flex;
                align-items: center;
                column-gap: 10px;
                padding-top: 0;
                padding-bottom: 15px;
            }
        }
    }
}

textarea.form-control {
    overflow: hidden;
    padding: 15px;
}

label.highligh-title {
    margin-bottom: 10px;
}

.confirm.modal {

    .modal-dialog.modal-dialog-centered {
        max-width: 500px !important;
    }

    .modal-header {
        padding: 1rem 20px !important;
    }

    .modal-body {
        padding: 20px !important;

        .message {
            margin-bottom: 10px;
        }

        button.btn.btn-primary {
            min-width: 90px !important;
        }
    }
}

.modal-confirm {
    .modal-dialog.modal-dialog-centered {
        max-width: 700px !important;
    }

    .modal-content {
        background-color: transparent;
        overflow: visible;
        border: none;
    }

    submitconfirmation-popup {
        overflow: visible !important;
    }

}

.gradient-backdrop {
    background: linear-gradient(135deg, #DBEAD1 0%, #A3B1D6 100%) !important;
    opacity: 0.8 !important;
}

.manual-guide {
    display: flex;
    column-gap: 10px;

    span.question-mark {
        background: #7BB155;
        padding: 2px 8px;
        border-radius: 50%;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #7BB155;
        transition: all 0.2s ease;
        width: 21px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: transparent;
            color: #7BB155;
        }
    }
}


@media screen and (max-width: 1350px) {
    .nav-container .tab-content {
        padding: 25px 0 !important;
        max-height: calc(100vh - 215px);
        overflow: auto;
        overflow-x: hidden;
    }

    .com-space {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    mk-container .mk-header {
        padding-top: 16px !important;
        padding-bottom: 16px !important;
    }

    mk-footer .mk-footer {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }

    .pitch-wrap .tab-content {
        padding: 0 !important;
        max-height: inherit !important;
    }
}

.user-fields span.mk2selected-value {
    padding-left: 15px !important;
    border: 1px solid #ced4da !important;
}

.user-fields .mk2select-container .mk2select-options {
    border: 1px solid #ced4da !important;
}

mk-select2 {
    position: relative;

    &.ng-touched.ng-invalid,
    &.ng-dirty.ng-invalid {

        input,
        .dropdown-btn,
        .mk2selected-value {
            border: 1px solid #F00 !important;
        }
    }

    mk-error {
        position: absolute;
        top: 0;
        right: 15px;
    }
}

.searchselect {

    &.ng-touched.ng-invalid,
    &.ng-dirty.ng-invalid {
        ul.mk2select-options.show li.mk2select-option.search-box {
            input {
                border: 1px solid #F00 !important;
            }
        }
    }
}

textarea {
    &.ng-touched.ng-invalid {
        border: 1px solid #f00 !important;
    }

    &.ng-pristine.ng-invalid.ng-touched {
        border: 1px solid #f00 !important;
    }
}


.web-cam-modal .modal-lg {
    --bs-modal-width: 100%;
    height: 100%;
    margin: 0;

    .modal-content {
        max-height: 100%;
        overflow: hidden;
        height: 100%;
    }
}

web-cam {
    height: 100vh;

    .webcam-wrap {
        height: 100%;
    }
}